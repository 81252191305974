<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div style="margin-bottom:10px">
        <v-btn color="primary" @click="goHome()">
          Ga terug
        </v-btn>
      </div>
      <v-row no-gutters style="margin-top:30px">
        <v-col cols="5" sm="5" style="margin-right: 20px;">
          <h4 class="text-center">
            Persoonlijke gegevens
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" color="warning" v-on="on" dark right>
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Mailadres kan alleen via Support gewijzigd worden.</span>
            </v-tooltip>
          </h4>

          <v-text-field
            v-model="customerInfo.name"
            label="Voornaam en Achternaam"
            outlined
            clearable
          ></v-text-field>

          <v-text-field
            v-model="customerInfo.adres"
            label="Adres"
            outlined
            clearable
          ></v-text-field>

          <v-text-field
            v-model="customerInfo.postcode"
            label="Postcode"
            outlined
            clearable
          ></v-text-field>

          <v-text-field
            v-model="customerInfo.email"
            label="Emailadres"
            outlined
            clearable
            disabled
          >
          </v-text-field>

          <v-text-field
            label="Telefoonnummer"
            outlined
            clearable
            v-model="customerInfo.phone"
          ></v-text-field>
          <v-btn color="primary" @click="updateProfile()"
            >Gegevens updaten
          </v-btn>
          <v-checkbox
            color="blue"
            v-model="checkbox"
            label="Zakelijke boeking"
          ></v-checkbox>
        </v-col>
        <v-col cols="5" md="5" v-if="checkbox">
          <h4 class="text-center">Factuur gegevens</h4>

          <v-text-field
            label="Bedrijfsnaam"
            v-model="customerInfo.company"
            outlined
            clearable
          ></v-text-field>

          <v-text-field
            label="Kvk nummer"
            v-model="customerInfo.kvk"
            outlined
            clearable
          ></v-text-field>

          <v-text-field
            label="BTW"
            v-model="customerInfo.btw"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import apiUser from "../api/apiUser";
export default {
  data() {
    return {
      customerInfo: [],
      checkbox: true,
    };
  },

  async mounted() {
    apiUser.getCurrentUser().then((data) => {
      this.customerInfo = data;
    });
  },

  methods: {
    async updateProfile() {
      await axios.put(
        `https://server.kantoorflex.nl/api/rest/v2/customer/update/profile`,
        this.customerInfo
      );

      this.$swal.fire("Gewijzigd!", "", "success");
      await this.getCurrentUSer();
    },
    goHome() {
      this.$router.push("/KlantPortaal");
    },
  },
};
</script>
