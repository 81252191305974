<template>
  <MyProfile />
</template>

<script>
import MyProfile from "@/components/user/MyProfile";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    MyProfile,
  },
  metaInfo: {
    title: "Klant Dashboard | Kantoofflex",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>